/* navbar*/  
/* .navbar{
    left:100px;
    right:100px;
    } */
h1 {
  font-size: 5vw;
}
h4 {
  font-size: 2.7vw;
}
.header {
  background: url(../img/img-header.jpg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
}
.cambio .navbar {
  font-family: monospace !important;
  background-color: transparent !important;
}
.cambio .navbar .nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 4rem;
  font-size: 1.8em;
}
.cambiomovido .navbar .nav-link {
  font-size: 1.8em;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 4rem;
}
.cambiomovido .navbar {
  font-family: monospace !important;
  background-color: black !important;
  opacity: 0.5 ;
/*  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  /*  box-shadow: 3px 4px 8px 2px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 3px 4px 8px 2px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 3px 4px 8px 2px rgba(0, 0, 0, 0.53);
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100% 
  );
  opacity: 0.5; */
}

.contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: aliceblue;

  margin: 0;
  position: absolute;
  top: 10%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.letrasmoviles {
  color: aliceblue;
  font-family: monospace !important;
}
.highlighted-text {
  color: var(--dark-orange);
}

.logofg {
  max-width: 20%;
  height: auto;
 /*  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  /* FadeIn */
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;
}

/* Efect */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* burbujas */

.burbuja{
  border-radius: 50%;
  background: #fff;
  opacity: .3;

  position: absolute;
  bottom: -150;
  
  animation: burbujas 3s linear infinite ;
}

.burbuja:nth-child(1){
  width: 80px;
  height: 80px;
  left: 5%;
  animation-duration: 3s;
  animation-delay: 3s;
}

.burbuja:nth-child(2){
  width: 100px;
  height: 100px;
  left: 35%;
  animation-duration: 3s;
  animation-delay: 5s;
}

.burbuja:nth-child(3){
  width: 20px;
  height: 20px;
  left: 15%;
  animation-duration: 1.5s;
  animation-delay: 7s;
}

.burbuja:nth-child(4){
  width: 50px;
  height: 50px;
  left: 90%;
  animation-duration: 6s;
  animation-delay: 3s;
}

.burbuja:nth-child(5){
  width: 70px;
  height: 70px;
  left: 65%;
  animation-duration: 3s;
  animation-delay: 1s;
}

.burbuja:nth-child(6){
  width: 20px;
  height: 20px;
  left: 50%;
  animation-duration: 4s;
  animation-delay: 5s;
}

.burbuja:nth-child(7){
  width: 20px;
  height: 20px;
  left: 50%;
  animation-duration: 4s;
  animation-delay: 5s;
}

.burbuja:nth-child(8){
  width: 100;
  height: 100px;
  left: 52%;
  animation-duration: 5s;
  animation-delay: 5s;
}

.burbuja:nth-child(9){
  width: 65px;
  height: 65px;
  left: 51%;
  animation-duration: 3s;
  animation-delay: 2s;
}

.burbuja:nth-child(10){
  width: 40px;
  height: 40px;
  left: 35%;
  animation-duration: 3s;
  animation-delay: 4s;
}


@keyframes burbujas{
  0%{
      bottom: 0;
      opacity: 0;
  }
  30%{
      transform: translateX(30px);
  }
  50%{
      opacity: .4;
  }
  100%{
      bottom: 100vh;
      opacity: 0;
  }
}

@keyframes movimiento{
  0%{
      transform: translateY(0);
  }
  50%{
      transform: translateY(30px);
  }
  100%{
      transform: translateY(0);
  }
}






.About{
    background-color: black;
   /*  background: rgb(214,223,213);
background: linear-gradient(90deg, rgba(214,223,213,1) 0%, rgba(186,224,232,1) 100%); */
    min-height: 100vh;
}

.form {
    min-height: 100vh;
}
.itemes
{
  font-family: monospace !important;
  position: relative;
  margin-left: 0vw;
  margin-top: 5vw;
   text-shadow: 1px 1px 2px black, 0 0 1em rgb(41, 41, 49), 0 0 0.2em rgb(29, 29, 53);
   color: white;
   font: 2.5vw Georgia, "Bitstream Charter", "URW Bookman L", "Century Schoolbook L", serif;
   text-align: center;
}
.rojo {
    font-size: 3em;
     color: red;
   }
   .negro {
    font-size: 3em;
    color: black;
  }
  .redes :hover{
    text-shadow: 1px 1px 1px black, 0 0 0.1em rgb(41, 41, 49), 0 0 0.1em rgb(29, 29, 53);
}

.servicios{
   /* fallback for old browsers */
  background: #7d7d7d;

    min-height: 100vh;
}
.contenedor
{
display: flex;
flex-direction: column;
align-items: center;

color: aliceblue;

margin: 0;
position: absolute;
top: 80%;
left: 50%;
-ms-transform: translate(-10%, -30%);
transform: translate(-10%, -20%);
}

.titulo
{
  font-family: monospace !important;
  position: relative;
  top: 36px;
   text-shadow: 1px 1px 2px black, 0 0 1em rgb(41, 41, 49), 0 0 0.2em rgb(29, 29, 53);
   color: white;
   font: 3.5vw Georgia, "Bitstream Charter", "URW Bookman L", "Century Schoolbook L", serif;
}
.items
{
  font-family: monospace !important;
  position: relative;
  margin-left: 3em;
 
   text-shadow: 1px 1px 2px black, 0 0 1em rgb(41, 41, 49), 0 0 0.2em rgb(29, 29, 53);
   color: white;
   font: Georgia, "Bitstream Charter", "URW Bookman L", "Century Schoolbook L", serif;
   font-size: 2.5vw;
}
.imagen
{
    max-width: 90%;
    height: auto;
}

.footer{
    background-color: black;
   /*  background: rgb(214,223,213);
background: linear-gradient(90deg, rgba(214,223,213,1) 0%, rgba(186,224,232,1) 100%); */
    min-height: 5vh;
    margin-top: 1%;
}
.blanco{
color:white;
} 